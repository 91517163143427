import { history } from "../../../../../_helpers";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { apiCaller } from "../../../../../_helpers";
import { TypeAssignExercise } from "../../../../../_constants/typeAssignExercise";
import { teacherService } from "../../../../../_services";
import { teacherConstants } from "../../../../../_constants";
import { floatObjectToArray } from "../../../../../_base/Validate";
import { alertActions } from "../../../../../_actions";

export const assignSpecificLogic = (props) => {
  const { id, type } = useParams();

  const _dispatch = useDispatch();
  const authentication = useSelector((state) => state.authentication);
  const exercises = useSelector((state) => state.classes.exercises);

  // Variable Data Assign Specific for Assign Demand
  const [dataAssignSpecific, setDataAssignSpecific] = useState(
    useSelector((state) => state.classes.setting_assign)
  );

  const [studySetting, setStudySetting] = useState(
    dataAssignSpecific?.study_data || []
  )

  const [isLoading, setLoading] = useState(false);

  //  Data Setting for assign
  const [currUnit, setCurrUnit] = useState(
    parseInt(dataAssignSpecific?.cur_unit) || 1
  );

  const [totalUnit, setTotalUnit] = useState(
    dataAssignSpecific?.total_unit || 12
  );

  const [currTimeMinute, setCurrTimeMinute] = useState(
    dataAssignSpecific?.expected_time || 30
  );

  const [cantChangeUnit, setCantChangeUnit] = useState(
    currUnit == 1 ? "prev" : currUnit == totalUnit ? "next" : ""
  );

  // Capacity
  const [cantChangeTime, setCantChangeTime] = useState(
    currTimeMinute == 10 ? "prev" : currTimeMinute == 180 ? "next" : ""
  );

  // check GTHN
  const [isLostRoot, setIsLostRoot] = useState(!!dataAssignSpecific?.is_lost_root || false)

  // GET Data Setting for assign
  const getDataSetting = async () => {
    setLoading(true);
    const res = await teacherService.getDataAssignSetting(
      id,
      JSON.stringify(exercises.students)
    );
    if (res?.status) {
      const data = res.data;
      data["expected_time"] = currTimeMinute;
      let transformData = {}

      const newStudyDataDefault = swapPositionArr(data?.study_data, 0, 2)?.map(item => {
        if(!!data?.is_lost_root) {
          return {
            ...item,
            practice_percent: 100,
            study_percent: 0,
          }
        }
        return item
      });
      const studyData = newStudyDataDefault?.map(item => {
        if(data?.cur_unit == data?.total_unit) {
          return {
            ...item,
            practice_percent: 100,
            study_percent: 0,
          }
        }
        return item
      });
      
      if(!!data?.is_lost_root) {
        transformData = {
          ...data,
          study_data: studyData,
          lesson_list_propose: data?.lesson_list_propose?.map(item => {
            if(item?.skill === "vocabulary" || item?.skill === "grammar") {
              return item
            }
            return null
          }).filter(item => !!item),
          level_skill: []
        }
      } else {
        transformData = {
          ...data,
          study_data: studyData,
        };
      }

      _dispatch({
        type: teacherConstants.GET_DATA_SETTING_ASSIGN,
        setting: transformData,
      });
      const curUnit = data.cur_unit > data?.total_unit ? data?.total_unit : data.cur_unit;
      setIsLostRoot(!!data?.is_lost_root)
      setLoading(false);
      setStudySetting(newStudyDataDefault)
      setDataAssignSpecific(transformData);
      setTotalUnit(parseInt(data.total_unit));
      setCurrUnit(parseInt(curUnit));
      setCantChangeUnit(
        parseInt(curUnit) == 1
          ? "prev"
          : parseInt(curUnit) == parseInt(data.total_unit)
          ? "next"
          : ""
      );
    } else {
      _dispatch(
        alertActions.error({
          message: res,
          screen: teacherConstants.SCREEN_ASSIGN_SETTING,
        })
      );
    }
  };
  
  useEffect(() => {
    !dataAssignSpecific?.cur_unit && getDataSetting();
  }, []);

  useEffect(() => {
    if (currUnit == totalUnit) {
      setDataAssignSpecific((data) => ({
        ...data,
        cur_unit: currUnit,
        study_data: dataAssignSpecific.study_data.map((ele) => {
          return {
            ...ele,
            practice_percent: 100,
            study_percent: 0,
          };
        }),
      }))
    } else {
      setDataAssignSpecific((data) => ({
        ...data,
        cur_unit: currUnit,
        study_data: studySetting,
      }))
    }
  }, [currUnit])

  // Get Data Proposal
  const getDataProposalCapacity = async () => {
    var body = new FormData();
    const studyData = swapPositionArr(dataAssignSpecific?.study_data, 0, 2);

    body.append("list_student", JSON.stringify(exercises.students));
    body.append("id_class", id.toString());
    body.append("unit_learning_id", dataAssignSpecific.cur_unit.toString());
    body.append("setting_student", JSON.stringify(studyData));
    body.append(
      "desired_time",
      JSON.stringify(dataAssignSpecific.expected_time)
    );

    const res = await teacherService.getDataAssignProposal("capacity", body);

    return res.data;
  };

  // Handle Go Back
  const handleGoBack = () => {
    _dispatch({
      type: teacherConstants.CLEAR_ONLY_DATA_EXERCISES,
    });
    localStorage.setItem("typeCurrExercise", "sunday");
    history.push(
      "/" +
        authentication.role +
        "/class/view/" +
        id +
        "/exercise/student/selection_assign"
    );
  };

  // Handle Submit Assign Demand
  const handleSubmitAssignDemand = async () => {
    _dispatch({
      type: teacherConstants.GET_DATA_SETTING_ASSIGN,
      setting: dataAssignSpecific,
    });

    if (type == TypeAssignExercise.DEMAND) {
      history.push(
        "/" +
          authentication.role +
          "/class/view/" +
          id +
          "/exercise/student/" +
          type +
          "/skills?unit_num=" + currUnit
      );
    } else if (type == TypeAssignExercise.CAPACITY) {
      const responseData = await getDataProposalCapacity();
      if (responseData) {
        responseData.map(
          (item) =>
            (item.exercise_suggest = floatObjectToArray(item.exercise_suggest))
        );
        
        _dispatch({
          type: teacherConstants.GET_DATA_ASSIGN_SPECIFIC,
          proposal: responseData.map((item) => ({
            ...item,
            exercise_suggest: item.exercise_suggest.map((exercise) => ({
              ...exercise,
              user_id: item.user_id,
              list_guide_id: [],
              unit: currUnit
            })),
            isRoot: true
          })),
        });

        const dataExercise = responseData.reduce(function (accumulator, obj) {
          return accumulator.concat(
            obj.exercise_suggest.map((exercise) => ({
              ...exercise,
              user_id: obj.user_id,
              list_guide_id: [],
              unit: currUnit
            }))
          );
        }, []);

        _dispatch({
          type: teacherConstants.ADD_DATA_EXERCISE,
          data: {
            ...exercises,
            data_exercise: dataExercise,
            unit: currUnit
          },
        });
      }

      history.push(
        "/" +
          authentication.role +
          "/class/view/" +
          id +
          "/exercise/student/" +
          type +
          "/list_proposals?unit_num=" + currUnit
      );
    }
  };

  // Handle Change Unit
  const handleChangeUnit = (type) => {
    console.log('data: ', dataAssignSpecific)
    switch (type) {
      case "prev":
        if (currUnit > 1) {
          setCurrUnit(currUnit - 1);
          setDataAssignSpecific((data) => ({
            ...data,
            cur_unit: currUnit - 1,
          }));
        }
        if (currUnit - 1 <= 1) {
          setCantChangeUnit("prev");
        } else {
          setCantChangeUnit("");
        }
        break;
      case "next":
        if (currUnit < totalUnit) {
          setCurrUnit(currUnit + 1);
          if (currUnit + 1 == totalUnit) {
            setDataAssignSpecific((data) => ({
              ...data,
              cur_unit: currUnit + 1,
              study_data: dataAssignSpecific.study_data.map((ele) => {
                return {
                  ...ele,
                  practice_percent: 100,
                  study_percent: 0,
                };
              }),
            }));
          } else {
            setDataAssignSpecific((data) => ({
              ...data,
              cur_unit: currUnit + 1,
            }));
          }
        }
        if (currUnit + 1 >= totalUnit) {
          setCantChangeUnit("next");
        } else {
          setCantChangeUnit("");
        }
        break;
      default:
    }
  };

  // Handle Change Time
  const handleChangeTime = (type) => {
    switch (type) {
      case "prev":
        if (currTimeMinute > 10) {
          setCurrTimeMinute(currTimeMinute - 10);
          setDataAssignSpecific((data) => ({
            ...data,
            expected_time: currTimeMinute - 10,
          }));
        }
        if (currTimeMinute - 10 <= 10) {
          setCantChangeTime("prev");
        } else {
          setCantChangeTime("");
        }
        break;
      case "next":
        if (currTimeMinute < 180) {
          setCurrTimeMinute(currTimeMinute + 10);
          setDataAssignSpecific((data) => ({
            ...data,
            expected_time: currTimeMinute + 10,
          }));
        }
        if (currTimeMinute + 10 >= 180) {
          setCantChangeTime("next");
        } else {
          setCantChangeTime("");
        }
        break;
      default:
    }
  };

  // Handle Change Percent
  const handleChangePercent = (data, value) => {
    if (value <= 100 && value >= 0) {
      let newStudyData = dataAssignSpecific.study_data;
      if(isLostRoot) {
        newStudyData.forEach((item) => {
            item.practice_percent = value;
            item.study_percent = 100 - value;
        });
      } else {
        newStudyData.forEach((item) => {
          if (item.stand == data.stand) {
            item.practice_percent = value;
            item.study_percent = 100 - value;
          }
        });
      }
      setDataAssignSpecific((data) => ({ ...data, study_data: newStudyData }));
      setStudySetting(newStudyData)
    }
  };

  return {
    isLoading,
    exercises,
    cantChangeTime,
    currUnit,
    cantChangeUnit,
    dataAssignSpecific,
    currTimeMinute,
    handleSubmitAssignDemand,
    setDataAssignSpecific,
    handleChangeUnit,
    handleChangePercent,
    handleGoBack,
    handleChangeTime,
    isLostRoot
  };
};

const swapPositionArr = (arr, indexA, indexB) => {
  const newArr = [...arr];
  var temp = newArr[indexA];
  newArr[indexA] = newArr[indexB];
  newArr[indexB] = temp;
  return newArr;
};
