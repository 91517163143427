import { curriculumConstants } from "../../_constants";
import { userConstants } from "../../_constants";
const firstState = {
  dataCurriculum: null,
  timeGetDataCurriculum: null,
  dataUnitByCurriculum: {},
  forTeacherId: null
};
var initialState = {
  ...firstState
};
if (localStorage.getItem("persistCurriculumTeacher")) {
  initialState = JSON.parse(localStorage.getItem("persistCurriculumTeacher"));
}

const persistCurriculumTeacher = (state = initialState, action) => {
  switch (action.type) {
    case curriculumConstants.SAVE_DETAIL_UNIT_TEACHER: {
      const newState = {
        ...state,
        dataUnitByCurriculum: {
          ...state.dataUnitByCurriculum,
          [action?.payload?.curriculumId]: {
            ...state.dataUnitByCurriculum?.[action?.payload?.curriculumId],
            [action?.payload?.unitId]: {
              data: action?.payload?.data,
              saveAt: new Date(),
              base_url: action?.payload?.base_url
            }
          }
        },
      }
      localStorage.setItem(
        "persistCurriculumTeacher",
        JSON.stringify(newState)
      );
      return newState;
    }
    case curriculumConstants.SAVE_DATA_CURRICULUM_TEACHER: {
      const newState = {
        ...state,
        dataCurriculum: action?.payload?.data,
        timeGetDataCurriculum: new Date(),
        forTeacherId: action?.payload?.userId
      }
      localStorage.setItem(
        "persistCurriculumTeacher",
        JSON.stringify(newState)
      );
      return newState;
    }
    case curriculumConstants.SAVE_DETAIL_CURRICULUM_TEACHER: {
      const newState = {
        ...state,
        dataCurriculum: {
          ...state?.dataCurriculum,
          courses: state?.dataCurriculum?.courses?.map(item => {
            if (item?.id == action?.id) {
              return {
                ...item,
                data_map: action.data_map
              }
            }
            return item;
          })
        }
      }
      localStorage.setItem(
        "persistCurriculumTeacher",
        JSON.stringify(newState)
      );
      return newState;
    }
    case curriculumConstants.RESET_DATA_CURRICULUM_TEACHER:
      localStorage.setItem(
        "persistCurriculumTeacher",
        JSON.stringify(firstState)
      );
      return firstState;
    case userConstants.RESET_ALL_STATE:
      return initialState;
    default:
      return state;
  }
};

export { persistCurriculumTeacher };
