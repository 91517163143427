import LazyLoad from "react-lazyload";
import { Header } from "../../../../_components/Admin/Header";
import { history } from "../../../../_helpers";
import { configConstants } from "../../../../_constants";
import './EntranceScore.style.scss'
import { EntranceScoreLogic } from "./EntranceScore.logic";
import { ReviewExam } from "../../../StudentPage/exam-test/ReviewExam";

function secondsToTime(e){
  const m = Math.floor(e % 3600 / 60).toString().padStart(2,'0')
  const s = Math.floor(e % 60).toString().padStart(2,'0');
  return m + ':' + s;
}

export default function EntranceScore() {
  const {
    listEntranceScore,
    isLoading,
    handleReviewMockTest,
    isShowReview,
    dataQuestion,
    resultExam,
    headingExam,
    handleCloseReviewExam
  } = EntranceScoreLogic()

  return (
    <div className="sunE-right-container entrance_score_container ">
      <Header
        title={"Điểm kiểm tra đầu vào"}
        isBack
        clickBack={() => history.goBack()}
      />
      <div
        className='sunE-content sunE-class-content flex-1'
        style={{ padding: 0 }}
      >
        <div
          className='sunE-class-list uk-container'
        >
          <LazyLoad once height={"80vh"} className='uk-grid'>
            {listEntranceScore?.map((item, i) => {
              return (
                  <div className={`uk-width-1-2`} key={i}>
                    <div className='class-box box-shadow'>
                      <div className='d-flex align-item-center' style={{ height: "100%", gap: '16px' }}>
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignSelf: 'flex-start', gap: 8}}>
                          <div className='student-avatar-box'>
                            <img
                              className='img-cover'
                              src={item?.avatar ? configConstants.BASE_URL + item?.avatar : ''}
                              alt='avatar'
                            />
                          </div>
                          <div className="d-flex justify-content-center">
                            <span className="size-text-info">Điểm:</span>
                            <span className="size-text-info student-info-score">{item?.test_done && item?.score >= 0 ? item?.score : '...'}</span>
                          </div>
                        </div>
                        <div className="d-flex student-info">
                          <p className="student-info-name">{item?.student_name}</p>
                          {item?.test_done && (
                            <>
                              <div className="flex-m align-item-center">
                                <div className="d-flex align-item-center">
                                  <span className="size-text-info">{`Số câu đúng/Tổng số câu: `}
                                  <b className="size-text-info">{`${item?.number_true || 0}/${item?.total_question}`}</b>
                                  </span>
                                </div>
                              </div>
                              {item?.duration && <div className="flex-m align-item-center">
                                <div className="d-flex align-item-center">
                                  <span className="size-text-info">{`Thời gian làm bài: `}
                                  <b className="size-text-info">{secondsToTime(item?.duration)}</b>
                                  </span>
                                </div>
                              </div>}
                              <div className="flex-m align-item-center">
                                <div className="d-flex align-item-center">
                                  {/* <img className="student-info-label-ico" src="/assets/images/icon/ico_clock.png" alt=""/> */}
                                  <span className="size-text-info">{`Thời gian nộp bài: `}
                                    <b className="size-text-info">{item?.time_send}</b>
                                  </span>
                                </div>
                              </div>
                            </>
                          )}
                          <p className="size-text-info">
                            {'Đề xuất: '}
                            <b className="size-text-info">
                              {item?.lost_root 
                                ? 'Giáo trình Sunday Esstential' 
                                : 'Giáo trình Sunday Success' }
                            </b>
                          </p>
                          <p 
                            className="bold hover-primary size-text-info" 
                            style={{
                              color: 'orange', 
                              textDecoration: 'underline', 
                              cursor: 'pointer'
                            }}
                            onClick={() => handleReviewMockTest(item)}
                          >
                            Xem bài làm
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
            })}
          </LazyLoad>
          {!listEntranceScore?.length && !isLoading && <p className="bold sunE-title-medium" style={{textAlign: 'center', marginTop: '80px'}}>Chưa có học sinh nào làm bài kiểm tra đầu vào.</p>}
        </div>
      </div>
      <ReviewExam
        // examInfo={infoQuestion}
        heading={headingExam}
        isShow={isShowReview}
        onClose={handleCloseReviewExam}
        listQuestions={dataQuestion}
        resultExam={resultExam}
      />
    </div>
  )
}