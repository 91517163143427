import React, { useState } from "react";
import "./styles.scss";
import { CheckBoxCustom, renderStatusCheckBox } from "./CheckBoxCustom";
import { isEmpty } from "lodash";
import ReviewLaterBox from "./ReviewLaterBox";
import { convertSingleQuote, isJsonString, validWord } from "../../_helpers/utils";
import { configConstants } from "../../_constants";
import { Audio, AudioCustom, AudioV2 } from "../Audio";

export const renderTextAnswerReview = (text, answer) => {
  let answers = answer?.map((textItem) => textItem.toLowerCase()?.replace(/[‘\u2018\u2019]/g, "'"));
  if (
    answers?.includes(
      text
        ?.toLowerCase()
        ?.trim()
        ?.replace(/[^A-Za-z0-9]+$/, "")
        ?.replace(/\s+/g, " ")
        ?.replace(/[‘\u2018\u2019]/g, "'")
    ) ||
    answers?.includes(
      text
        ?.toLowerCase()
        ?.trim()
        ?.replace(/[^A-Za-z0-9]+$/, "")
        ?.replace(/\s+/g, " ")
        ?.replace(/[‘\u2018\u2019]/g, "'")
        ?.concat(".")
    ) ||
    answers?.includes(
      text
        ?.toLowerCase()
        ?.trim()
        ?.replace(/[^A-Za-z0-9]+$/, "")
        ?.replace(/\s+/g, " ")
        ?.replace(/[‘\u2018\u2019]/g, "'")
        ?.concat("?")
    )
  ) {
    return (
      <div className="item-exam-result-text">
        <span className="text-answer-true">{text}</span>
        {/* <span className="text-answer-true">{answer.join('/ ')}</span> */}
      </div>
    );
  } else {
    return (
      <div className="item-exam-result-text">
        <span className="text-answer-false">{text}</span>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
        >
          <img
            style={{ position: "absolute", left: -36 }}
            src="/assets/images/ico_hover_answer.png"
          />
          <span className="text-answer-true">{answer[0]}</span>
        </div>
      </div>
    );
  }
};



export const ExamPro1 = (props) => {
  const { category, indexQuestion, resultExam = [], onChangeResult, isReview, listReview = [], onCheckReview, isDoing } =
    props;

  return (
    <div className="item-pronunuciation">
      <span className="title-question title_question_width">
        {category?.question_content}
        {category?.group_content}
      </span>
      {category?.listQuestions?.map((question, index) => {
        let answer = props?.userAnswerText?.trim() || resultExam.filter(
          (result) => result.questionId == question.question_id
        )[0]?.answer;
        const isActiveReview = listReview?.find(item => item?.question_id == question?.question_id)?.checked

        return (
          <div key={question.question_id} id={question.question_id} style={{position: 'relative', width: '100%'}}>
            {isDoing && <ReviewLaterBox isActive={isActiveReview} onClick={() => onCheckReview(question?.question_id)}/>}
            <div
              style={{
                display: "flex",
                marginTop: 12,
                flexDirection: isReview ? "column" : "row",
                width: '80%'
              }}
            >
              <label className="label">{!!props?.numberReview ? props?.numberReview : (index + indexQuestion)}. </label>
              <div className="row" style={{ marginLeft: 8 }}>
                {question?.list_option?.map((option) => {
                  let isOptionTrue = parseInt(option?.score) !== 0;
                  let isAnswerTrue =
                    answer == option?.match_option_text.join("");
                  return (
                    <div className="col-6 d-flex" key={option?.option_match_id}>
                      {isReview && isOptionTrue && !isAnswerTrue && (
                        <img
                          style={{ height: 24, marginLeft: -30 }}
                          src="/assets/images/ico_hover_answer.png"
                        />
                      )}
                      <CheckBoxCustom
                        status={renderStatusCheckBox(
                          isAnswerTrue,
                          isOptionTrue,
                          isReview
                        )}
                        onChange={() =>
                          onChangeResult(
                            question.question_id,
                            option?.match_option_text.join("")
                          )
                        }
                        label={option?.match_option_text
                          ?.join("")
                          .replace("[", "<u style='font-size:17.9px'>")
                          .replace("]", "</u>")}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            {isReview &&
              !isEmpty(question?.explain_parse?.content_question_text) && (
                <div className="text-explain-exam" style={{width: '100%'}}>
                  <label>Giải thích</label>
                  <div style={{ whiteSpace: "break-spaces" }}>
                    {question?.explain_parse?.content_question_text}
                  </div>
                </div>
              )}
          </div>
        );
      })}
    </div>
  );
};

const convertObjectToString = (arr = [], index, newValue) => {
  arr[index] = { ...arr[index], value: newValue };
  return arr;
};

export const renderFormAnswer = (
  options = {},
  answer,
  isEnter = true,
  onChange = () => {}
) => {
  let question = "";
  for (let option of options) {
    question = option.question_content;
  }
  let textsMatch = getFromBetween.get(question, "{", "}");
  for (let text of textsMatch) {
    question = question.replace(`{${text}}`, "{}");
  }
  let arrQuestion = question.split("{}");
  let arrQuestionResult = [];
  arrQuestion.forEach((item, index) => {
    if (index !== 0 || item.length == 0) {
      arrQuestionResult.push({
        type: "input",
        value: answer ? answer[arrQuestionResult.length]?.value || "" : "",
      });
    }
    arrQuestionResult.push({
      type: "text",
      value: item,
    });
  });

  return (
    <div style={{ display: "flex", marginLeft: 8, flexWrap: "wrap" }}>
      {arrQuestionResult.map((item, index) => {
        if (item?.type == "text") {
          return (
            <span
              className="begin-text"
              key={"enter-key-" + item?.value + index}
            >
              {item?.value}
            </span>
          );
        } else {
          if (isEnter) {
            return (
              <input
                className="form-control"
                placeholder="Nhập câu trả lời..."
                value={item?.value}
                onChange={(e) =>
                  onChange(
                    convertObjectToString(
                      arrQuestionResult,
                      index,
                      convertSingleQuote(e.target.value)
                    )
                  )
                }
                key={"enter-key-" + index}
              />
            );
          } else {
            return (
              <span key={"line-key-" + index} style={{ margin: "0 6px" }}>
                ________
              </span>
            );
          }
        }
      })}
    </div>
  );
};

export const ExamGrammar1 = (props) => {
  const { category, indexQuestion, resultExam = [], onChangeResult, isReview, listReview = [], onCheckReview, isDoing = false } =
    props;

  return (
    <div style={{ marginTop: 16 }}>
      <label className="title-question title_question_width">
        {category?.group_content}
      </label>
      {category?.listQuestions?.map((question, index) => {
        let answer = resultExam?.filter(
          (result) => result?.questionId == question?.question_id
        )[0]?.answer;
        let textAnswer = props?.userAnswerText?.trim() || (!!answer
          ? (typeof answer === 'string' ? answer : answer?.find((item) => item?.type == "input")?.value?.trim())
          : "");
        let matchOptionText = question?.list_option[0].match_option_text?.map(
          (text) => text
        );
        const isActiveReview = listReview?.find(item => item?.question_id == question?.question_id)?.checked
        return (
          <div key={question.question_id} id={question.question_id} style={{position: 'relative'}}>
            {isDoing && <ReviewLaterBox isActive={isActiveReview} onClick={() => onCheckReview(question?.question_id)}/>}
            <div className="item-grammar1">
              <label className="order">{!!props?.numberReview ? props?.numberReview : (index + indexQuestion)}.</label>
              {renderFormAnswer(
                question?.list_option,
                answer,
                !isReview,
                (value) => onChangeResult(question.question_id, value)
              )}
            </div>
            {isReview && renderTextAnswerReview(textAnswer, matchOptionText)}
            {isReview &&
              !isEmpty(question?.explain_parse?.content_question_text) && (
                <div className="text-explain-exam">
                  <label>Giải thích</label>
                  <div style={{ whiteSpace: "break-spaces" }}>
                    {question?.explain_parse?.content_question_text}
                  </div>
                </div>
              )}
          </div>
        );
      })}
    </div>
  );
};

var getFromBetween = {
  results: [],
  string: "",
  getFromBetween: function (sub1, sub2) {
    if (this.string?.indexOf(sub1) < 0 || this.string?.indexOf(sub2) < 0)
      return false;
    var SP = this.string?.indexOf(sub1) + sub1?.length;
    var string1 = this.string?.substr(0, SP);
    var string2 = this.string?.substr(SP);
    var TP = string1?.length + string2?.indexOf(sub2);
    return this.string?.substring(SP, TP);
  },
  removeFromBetween: function (sub1, sub2) {
    if (this.string?.indexOf(sub1) < 0 || this.string?.indexOf(sub2) < 0)
      return false;
    var removal = sub1 + this.getFromBetween(sub1, sub2) + sub2;
    this.string = this.string?.replace(removal, "");
  },
  getAllResults: function (sub1, sub2) {
    // first check to see if we do have both substrings
    if (this.string?.indexOf(sub1) < 0 || this.string?.indexOf(sub2) < 0) return;

    // find one result
    var result = this.getFromBetween(sub1, sub2);
    // push it to the results array
    this.results.push(result);
    // remove the most recently found one from the string
    this.removeFromBetween(sub1, sub2);

    // if there's more substrings
    if (this.string?.indexOf(sub1) > -1 && this.string?.indexOf(sub2) > -1) {
      this.getAllResults(sub1, sub2);
    } else return;
  },
  get: function (string, sub1, sub2) {
    this.results = [];
    this.string = string;
    this.getAllResults(sub1, sub2);
    return this.results;
  },
};

export const ExamGrammar9 = (props) => {
  const { category, indexQuestion, resultExam = [], onChangeResult, isReview, listReview = [], onCheckReview, isDoing = false } =
    props;
  return (
    <div style={{ marginTop: 16 }}>
      <label className="title-question title_question_width">
        {category?.group_content}
      </label>
      {category?.listQuestions?.map((question, index) => {
        let answer = props?.userAnswerText || resultExam.filter(
          (result) => result.questionId == question.question_id
        )[0]?.answer;
        let options = getFromBetween.get(question?.question_content, "[", "]");
        let matchOptionText = question?.list_option[0].match_option_text?.map(
          (text) => text.toLowerCase()
        );
        const isActiveReview = listReview?.find(item => item?.question_id == question?.question_id)?.checked
        return (
          <div key={question.question_id} id={question.question_id} style={{position: 'relative'}}>
            {isDoing && <ReviewLaterBox isActive={isActiveReview} onClick={() => onCheckReview(question?.question_id)}/>}
            <div
              className="item-grammar9"
              style={{ flexDirection: isReview ? "column" : "row" }}
            >
              <span
                className="label"
                style={{ whiteSpace: "break-spaces" }}
                dangerouslySetInnerHTML={{
                  __html:
                    (!!props?.numberReview ? props?.numberReview : (index +
                    indexQuestion)) +
                    ". " +
                    question?.question_content
                      ?.replaceAll("[", "<u>")
                      ?.replaceAll("]", "</u>"),
                }}
              ></span>
              <div className="row">
                {category.question_type == "11"
                  ? options.map((option) => {
                      let isOptionTrue =
                        matchOptionText.join("") == option?.toLowerCase();
                      let isAnswerTrue = answer == option;
                      return (
                        <div className="col-12 d-flex" key={option}>
                          {isReview && isOptionTrue && !isAnswerTrue && (
                            <img
                              style={{ height: 24, marginLeft: -30 }}
                              src="/assets/images/ico_hover_answer.png"
                            />
                          )}
                          <CheckBoxCustom
                            status={renderStatusCheckBox(
                              isAnswerTrue,
                              isOptionTrue,
                              isReview
                            )}
                            onChange={() =>
                              onChangeResult(question.question_id, option)
                            }
                            label={option}
                          />
                        </div>
                      );
                    })
                  : question?.list_option?.map((option) => {
                      let isOptionTrue = parseInt(option?.score) !== 0;
                      let isAnswerTrue =
                        answer == option?.match_option_text.join("");
                      return (
                        <div
                          className="col-12 d-flex"
                          key={option?.option_match_id}
                        >
                          {isReview && isOptionTrue && !isAnswerTrue && (
                            <img
                              style={{ height: 24, marginLeft: -30 }}
                              src="/assets/images/ico_hover_answer.png"
                            />
                          )}
                          <CheckBoxCustom
                            status={renderStatusCheckBox(
                              isAnswerTrue,
                              isOptionTrue,
                              isReview
                            )}
                            onChange={() =>
                              onChangeResult(
                                question.question_id,
                                option?.match_option_text.join("")
                              )
                            }
                            label={option?.match_option_text?.join("")}
                          />
                        </div>
                      );
                    })}
              </div>
            </div>
            {isReview &&
              !isEmpty(question?.explain_parse?.content_question_text) && (
                <div className="text-explain-exam">
                  <label>Giải thích</label>
                  <div style={{ whiteSpace: "break-spaces" }}>
                    {question?.explain_parse?.content_question_text}
                  </div>
                </div>
              )}
          </div>
        );
      })}
    </div>
  );
};

export const ExamReading1 = (props) => {
  const {
    type,
    title,
    category,
    indexQuestion,
    resultExam = [],
    onChangeResult,
    isReview,
    listReview = [],
    onCheckReview,
    isDoing = false,
  } = props;

  const renderQuestionsReading1 = () => {
    return category?.listQuestions?.map((question, index) => {
      let answer = props?.userAnswerText || props?.userAnswerText || resultExam.filter(
        (result) => result.questionId == question.question_id
      )[0]?.answer;
        const isActiveReview = listReview?.find(item => item?.question_id == question?.question_id)?.checked
      return (
        <div key={type + question.question_id} id={question.question_id} style={{position: 'relative'}}>
          {isDoing && <ReviewLaterBox isActive={isActiveReview} onClick={() => onCheckReview(question?.question_id)}/>}
          <div
            className="item-grammar9"
            style={{ flexDirection: isReview ? "column" : "row" }}
          >
            <span className="label">
              {!!props?.numberReview ? props?.numberReview : (index + indexQuestion)}. {question.question_content}
            </span>
            <div className="row">
              {question?.list_option.map((option) => {
                let isOptionTrue = parseInt(option?.score) !== 0;
                let isAnswerTrue =
                  validWord(answer) == validWord(option?.match_option_text.join(""));
                return (
                  <div className="col-12 d-flex" key={option?.option_match_id}>
                    {isReview && isOptionTrue && !isAnswerTrue && (
                      <img
                        style={{ height: 24, marginLeft: -30 }}
                        src="/assets/images/ico_hover_answer.png"
                      />
                    )}
                    <CheckBoxCustom
                      status={renderStatusCheckBox(
                        isAnswerTrue,
                        isOptionTrue,
                        isReview
                      )}
                      onChange={() =>
                        onChangeResult(
                          question?.question_id,
                          option?.match_option_text.join("")
                        )
                      }
                      label={option?.match_option_text?.join("")}
                    />
                  </div>
                );
              })}
            </div>
          </div>

          {isReview &&
            !isEmpty(question?.explain_parse?.content_question_text) && (
              <div className="text-explain-exam">
                <label>Giải thích</label>
                <div style={{ whiteSpace: "break-spaces" }}>
                  {question?.explain_parse?.content_question_text}
                </div>
              </div>
            )}
        </div>
      );
    });
  };

  const renderQuestionsReading2 = () => {
    return category?.listQuestions?.map((question, index) => {
      let answer =props?.userAnswerText?.trim() || resultExam.filter(
        (result) => result.questionId == question.question_id
      )[0]?.answer;
      let matchOptionText = question?.list_option[0].match_option_text?.map(
        (text) => text
      );
      const isActiveReview = listReview?.find(item => item?.question_id == question?.question_id)?.checked
      return (
        <div key={type + question.question_id} id={question.question_id} style={{position: 'relative'}}>
          {isDoing && <ReviewLaterBox isActive={isActiveReview} onClick={() => onCheckReview(question?.question_id)}/>}
          <div className="item-question">
            <label className="order">{!!props?.numberReview ? props?.numberReview : (index + indexQuestion)}.</label>
            {isReview ? (
              renderTextAnswerReview(answer, matchOptionText)
            ) : (
              <input
                placeholder="Nhập câu trả lời..."
                value={answer}
                onChange={(e) =>
                  onChangeResult(question?.question_id, convertSingleQuote(e.target.value))
                }
              />
            )}
          </div>
          {isReview && !isEmpty(question?.option_explain) && (
            <div className="text-explain-exam">
              <label>Giải thích</label>
              <div style={{ whiteSpace: "break-spaces" }}>
                {question?.option_explain}
              </div>
            </div>
          )}
        </div>
      );
    });
  };

  const renderQuestionsReading3 = () => {
    return category?.listQuestions?.map((question, index) => {
      let answer = props?.userAnswerText?.trim() || resultExam.filter(
        (result) => result.questionId == question.question_id
      )[0]?.answer;
      let matchOptionText = question?.list_option[0].match_option_text?.map(
        (text) => text
      );
      const isActiveReview = listReview?.find(item => item?.question_id == question?.question_id)?.checked
      return (
        <div key={type + question.question_id} id={question.question_id} style={{position: 'relative'}}>
          {isDoing && <ReviewLaterBox isActive={isActiveReview} onClick={() => onCheckReview(question?.question_id)}/>}
          <div className="item-question">
            {question?.list_option?.map((option) => (
              <div
                style={{
                  display: "flex",
                  marginTop: 16,
                  flexDirection: isReview ? "column" : "row",
                }}
                key={option.option_match_id}
              >
                <label className="label">{!!props?.numberReview ? props?.numberReview : (index + indexQuestion)}.</label>
                {isReview ? (
                  renderTextAnswerReview(answer, matchOptionText)
                ) : (
                  <input
                    placeholder="Nhập câu trả lời..."
                    value={answer}
                    onChange={(e) =>
                      onChangeResult(question?.question_id, convertSingleQuote(e.target.value))
                    }
                  />
                )}
              </div>
            ))}
          </div>
          {isReview && !isEmpty(question?.option_explain) && (
            <div className="text-explain-exam">
              <label>Giải thích</label>
              <div style={{ whiteSpace: "break-spaces" }}>
                {question?.option_explain}
              </div>
            </div>
          )}
        </div>
      );
    });
  };

  // Reading 4
  const renderQuestionsReading4 = () => {
    return category?.listQuestions?.map((question, index) => {
      let answer = props?.userAnswerText?.trim() || resultExam.filter(
        (result) => result.questionId == question.question_id
      )[0]?.answer;
      let matchOptionText = question?.list_option[0].match_option_text?.map(
        (text) => text
      );
      const isActiveReview = listReview?.find(item => item?.question_id == question?.question_id)?.checked
      return (
        <div key={type + question.question_id} id={question.question_id} style={{position: 'relative'}}>
          {isDoing && <ReviewLaterBox isActive={isActiveReview} onClick={() => onCheckReview(question?.question_id)}/>}
          <div className="item-question">
            {question?.list_option?.map((option) => (
              <div
                style={{
                  display: "flex",
                  marginTop: 16,
                  flexDirection: isReview ? "column" : "row",
                }}
                key={option.option_match_id}
              >
                <label className="label">{!!props?.numberReview ? props?.numberReview : (index + indexQuestion)}.</label>
                {isReview ? (
                  renderTextAnswerReview(answer, matchOptionText)
                ) : (
                  <input
                    placeholder="Nhập câu trả lời..."
                    value={answer}
                    onChange={(e) =>
                      onChangeResult(question?.question_id, convertSingleQuote(e.target.value))
                    }
                  />
                )}
              </div>
            ))}
          </div>
          {isReview && !isEmpty(question?.option_explain) && (
            <div className="text-explain-exam">
              <label>Giải thích</label>
              <div style={{ whiteSpace: "break-spaces" }}>
                {question?.option_explain}
              </div>
            </div>
          )}
        </div>
      );
    });
  };

  const renderQuestionsReading6 = () => {
    return category?.listQuestions?.map((question, index) => {
      let answer = props?.userAnswerText || resultExam.filter(
        (result) => result.questionId == question.question_id
      )[0]?.answer;
      const isActiveReview = listReview?.find(item => item?.question_id == question?.question_id)?.checked
      return (
        <div
          className="item-grammar9"
          key={type + question.question_id}
          id={question.question_id}
          style={{position: 'relative'}}
        >
          {isDoing && <ReviewLaterBox isActive={isActiveReview} onClick={() => onCheckReview(question?.question_id)}/>}
          <div style={{ maginTop: 16 }}>
            <span className="label">
              {!!props?.numberReview ? props?.numberReview : (index + indexQuestion)}. {question.question_content}
            </span>
            <div className="row">
              {question?.list_option?.map((option) => {
                let isOptionTrue = parseInt(option?.score) !== 0;
                let isAnswerTrue =
                  answer == option?.match_option_text.join("");
                return (
                  <div className="col-12" key={option.option_match_id}>
                    <div className="col-12 d-flex">
                      {isReview && isOptionTrue && !isAnswerTrue && (
                        <img
                          style={{ height: 24, marginLeft: -30 }}
                          src="/assets/images/ico_hover_answer.png"
                        />
                      )}
                      <CheckBoxCustom
                        status={renderStatusCheckBox(
                          isAnswerTrue,
                          isOptionTrue,
                          isReview
                        )}
                        onChange={() =>
                          onChangeResult(
                            question?.question_id,
                            option?.match_option_text.join("")
                          )
                        }
                        label={option.match_option_text.join("")}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            {isReview &&
              !isEmpty(question?.explain_parse?.content_question_text) && (
                <div className="text-explain-exam">
                  <label>Giải thích</label>
                  <div style={{ whiteSpace: "break-spaces" }}>
                    {question?.explain_parse?.content_question_text}
                  </div>
                </div>
              )}
          </div>
        </div>
      );
    });
  };

  const renderQuestionsReading11 = () => {
    return category?.listQuestions?.map((question, index) => {
      let answer = props?.userAnswerText || resultExam.filter(
        (result) => result.questionId == question.question_id
      )[0]?.answer;
      let matchOptionText = question?.list_option[0].match_option_text?.map(
        (text) => text
      );
      const isActiveReview = listReview?.find(item => item?.question_id == question?.question_id)?.checked
      return (
        <div key={type + question.question_id} id={question.question_id} style={{position: 'relative'}}>
          {isDoing && <ReviewLaterBox isActive={isActiveReview} onClick={() => onCheckReview(question?.question_id)}/>}
          <div className="item-question">
            <h3 className="label">
              {!!props?.numberReview ? props?.numberReview : (index + indexQuestion)}. {question.question_content}
            </h3>
            {isReview ? (
              renderTextAnswerReview(answer, matchOptionText)
            ) : (
              <input
                className="full-width"
                placeholder="Nhập câu trả lời..."
                value={answer}
                onChange={(e) =>
                  onChangeResult(question?.question_id, convertSingleQuote(e.target.value))
                }
              />
            )}
          </div>

          {isReview &&
            !isEmpty(question?.explain_parse?.content_question_text) && (
              <div className="text-explain-exam">
                <label>Giải thích</label>
                <div style={{ whiteSpace: "break-spaces" }}>
                  {question?.explain_parse?.content_question_text}
                </div>
              </div>
            )}
        </div>
      );
    });
  };

  const renderQuestionsReading12 = () => {
    return category?.listQuestions?.map((question, index) => {
      let answer = props?.userAnswerText?.trim() || resultExam.filter(
        (result) => result.questionId == question.question_id
      )[0]?.answer;
      let optionTextTrue = question?.list_option[0]?.match_option_text.join("");
      const isActiveReview = listReview?.find(item => item?.question_id == question?.question_id)?.checked
      return (
        <div key={type + question.question_id} id={question.question_id} style={{position: 'relative'}}>
          {isDoing && <ReviewLaterBox isActive={isActiveReview} onClick={() => onCheckReview(question?.question_id)}/>}
          <div className="item-grammar9">
            <div style={{ marginTop: 16 }}>
              <span className="label">
                {!!props?.numberReview ? props?.numberReview : (index + indexQuestion)}. {question.question_content}
              </span>
              <div className="row">
                <div className="col-6 d-flex">
                  {isReview && optionTextTrue == "T" && answer !== "T" && (
                    <img
                      style={{ height: 24, marginLeft: -30 }}
                      src="/assets/images/ico_hover_answer.png"
                    />
                  )}
                  <CheckBoxCustom
                    label="True"
                    status={renderStatusCheckBox(
                      answer == "T",
                      optionTextTrue == "T",
                      isReview
                    )}
                    onChange={() => onChangeResult(question?.question_id, "T")}
                  />
                </div>
                <div className="col-6 d-flex">
                  {isReview && optionTextTrue == "F" && answer !== "F" && (
                    <img
                      style={{ height: 24, marginLeft: -30 }}
                      src="/assets/images/ico_hover_answer.png"
                    />
                  )}
                  <CheckBoxCustom
                    label="False"
                    status={renderStatusCheckBox(
                      answer == "F",
                      optionTextTrue == "F",
                      isReview
                    )}
                    onChange={() => onChangeResult(question?.question_id, "F")}
                  />
                </div>
              </div>
            </div>
          </div>
          {isReview &&
            !isEmpty(question?.explain_parse?.content_question_text) && (
              <div className="text-explain-exam">
                <label>Giải thích</label>
                <div style={{ whiteSpace: "break-spaces" }}>
                  {question?.explain_parse?.content_question_text}
                </div>
              </div>
            )}
        </div>
      );
    });
  };

  const renderQuestionsReading14 = () => {
    return category?.listQuestions?.map((question, index) => {
      let answer = resultExam.filter(
        (result) => result.questionId == question.question_id
      )[0]?.answer;
      let textAnswer = props?.userAnswerText?.trim() || (answer
        ? answer.find((item) => item.type == "input")?.value?.trim()
        : "");
      let matchOptionText = question?.list_option[0].match_option_text?.map(
        (text) => text
      );
      const isActiveReview = listReview?.find(item => item?.question_id == question?.question_id)?.checked
      return (
        <div key={type + question.question_id} id={question.question_id} style={{position: 'relative'}}>
          {isDoing && <ReviewLaterBox isActive={isActiveReview} onClick={() => onCheckReview(question?.question_id)}/>}
          <div className="item-grammar1">
            <label className="order">{!!props?.numberReview ? props?.numberReview : (index + indexQuestion)}.</label>
            {renderFormAnswer(
              question?.list_option,
              answer,
              !isReview,
              (value) => onChangeResult(question.question_id, value)
            )}
          </div>
          {isReview && renderTextAnswerReview(textAnswer, matchOptionText)}
          {isReview && !isEmpty(question?.option_explain) && (
            <div className="text-explain-exam">
              <label>Giải thích</label>
              <div style={{ whiteSpace: "break-spaces" }}>
                {question?.option_explain}
              </div>
            </div>
          )}
        </div>
      );
    });
  };

  const renderQuestionsReading15 = () => {
    return category?.listQuestions?.map((question, index) => {
      let answer = props?.userAnswerText || props?.userAnswerText || resultExam.filter(
        (result) => result.questionId == question.question_id
      )[0]?.answer;
        const isActiveReview = listReview?.find(item => item?.question_id == question?.question_id)?.checked
      return (
        <div key={type + question.question_id} id={question.question_id} style={{position: 'relative'}}>
          {isDoing && <ReviewLaterBox isActive={isActiveReview} onClick={() => onCheckReview(question?.question_id)}/>}
          <div
            className="item-grammar9"
            style={{ flexDirection: isReview ? "column" : "row" }}
          >
            <span className="label">
              {!!props?.numberReview ? props?.numberReview : (index + indexQuestion)}. {question.question_content}
            </span>
            <div className="row">
              {question?.list_option.map((option) => {
                let isOptionTrue = parseInt(option?.score) !== 0;
                let isAnswerTrue =
                  answer == option?.match_option_text.join("");
                return (
                  <div className="col-12 d-flex" key={option?.option_match_id}>
                    {isReview && isOptionTrue && !isAnswerTrue && (
                      <img
                        style={{ height: 24, marginLeft: -30 }}
                        src="/assets/images/ico_hover_answer.png"
                      />
                    )}
                    <CheckBoxCustom
                      status={renderStatusCheckBox(
                        isAnswerTrue,
                        isOptionTrue,
                        isReview
                      )}
                      onChange={() =>
                        onChangeResult(
                          question?.question_id,
                          option?.match_option_text.join("")
                        )
                      }
                      label={option?.match_option_text?.join("")}
                    />
                  </div>
                );
              })}
            </div>
          </div>

          {isReview &&
            !isEmpty(question?.explain_parse?.content_question_text) && (
              <div className="text-explain-exam">
                <label>Giải thích</label>
                <div style={{ whiteSpace: "break-spaces" }}>
                  {question?.explain_parse?.content_question_text}
                </div>
              </div>
            )}
        </div>
      );
    });
  };

  const renderListQuestions = () => {
    switch (type) {
      case "reading1":
        return renderQuestionsReading1();
      case "reading2":
        return renderQuestionsReading2();
      case "reading3":
        return renderQuestionsReading3();
      case "reading4":
        return renderQuestionsReading4();
      case "reading6":
        return renderQuestionsReading6();
      case "reading11":
        return renderQuestionsReading11();
      case "reading12":
        return renderQuestionsReading12();
      case "reading14":
        return renderQuestionsReading14();
      case "reading15":
        return renderQuestionsReading15();
    }
  };

  // console.log(category);

  const renderContent = (group_script) => {
    let content = group_script;
    if (type == "reading3") {
      category?.jamming_answer_parse?.forEach((text) => {
        if (content.includes(`{${text}}`)) {
          category?.listQuestions?.forEach((question, index) => {
            if (!content.includes(`(${!!props?.numberReview ? props?.numberReview : (indexQuestion + index)})`)) {
              content = content.replace(
                `{${text}}`,
                `______(${!!props?.numberReview ? props?.numberReview : (indexQuestion + index)})`
              );
            }
          });
        }
      });
    } else if (type == "reading4") {
      // console.log(category?.match_option_text);
      category?.match_option_text.forEach((text) => {
        if (content.includes(`{${text}}`)) {
          category?.listQuestions?.forEach((question, index) => {
            if (!content.includes(`(${!!props?.numberReview ? props?.numberReview : (indexQuestion + index)})`)) {
              content = content.replace(
                `{${text}}`,
                `______(${!!props?.numberReview ? props?.numberReview : (indexQuestion + index)})`
              );
            }
          });
        }
      });
    } else if (type == "reading2") {
      category?.match_option_text.forEach((text) => {
        if (content.includes(`{${text}}`)) {
          category?.listQuestions?.forEach((question, index) => {
            if (!content.includes(`(${!!props?.numberReview ? props?.numberReview : (indexQuestion + index)})`)) {
              content = content.replace(
                `{${text}}`,
                `______(${!!props?.numberReview ? props?.numberReview : (indexQuestion + index)})`
              );
            }
          });
        }
      });
    } else if (type == "reading6") {
      content = content.replaceAll("{", "(").replaceAll("}", ")______");
    }
    return content;
  };

  const groupFile = category?.group_file && isJsonString(category?.group_file) ? JSON.parse(category.group_file) : category?.group_file;

  return (
    <div className="item-reading1 text-align-justify ">
      <label className="title-question title_question_width">
        {category?.group_content}
      </label>

      <div
        className="title-question text-center"
        style={{ fontSize: 20, margin: "1rem 0" }}
      >
        {category?.group_title ? category?.group_title : ""}
      </div>
      {type == "reading3" && (
        <div className="key-word-sugguest">
          {category?.jamming_answer_parse?.map((item) => (
            <span key={item}>{item}</span>
          ))}
        </div>
      )}
      <div className="content-document">
        <h3 className="title-content">{title}</h3>
        <span
          dangerouslySetInnerHTML={{
            __html: renderContent(category?.group_script),
          }}
        />
        {type == "reading2" && (
          <div>
            <span>{renderContent(category?.content)}</span>
          </div>
        )}
        {type == "reading4" && (
          <div>
            <span>{renderContent(category?.content)}</span>
          </div>
        )}
      </div>
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 24, paddingBottom: 30}}>
        {groupFile?.audio && <div className="content-audio" style={{width: '64%',marginBottom: 0}}>
          <AudioV2 link={configConstants.BASE_URL + groupFile?.audio} />
        </div>}
        {groupFile?.image && 
          <img src={configConstants.BASE_URL + groupFile?.image} style={{width: '64%', height: 'auto', aspectRatio: 4/3, borderRadius:12}}/>
        }
        {groupFile?.video && 
          <Audio classNameVideo="video-reading15" type="video" link={configConstants.BASE_URL + groupFile?.video} style={{width: '64%', height: 'auto', aspectRatio: 16/9, borderRadius:12}}/>
        }
      </div>
      <div className={`content-question ${isReview ? 'content-question-review' : ''}`}>{renderListQuestions()}</div>
    </div>
  );
};
