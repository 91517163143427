import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { curriculumActions, studentActions } from "../../_actions";
import { Header } from "../../_components/Admin/Header";
import _, { findIndex } from "lodash";
import { useHistory } from "react-router-dom";
import { CricleProcess } from "../../_components/Process";
import { Alert } from "../../_components/Alert";
import { alertActions } from "../../_actions";
import { curriculumConstants, studentConstants } from "../../_constants";
import { convertSkillVN } from "../../_helpers/utils";
import { apiCaller } from "../../_helpers";
import moment from "moment";

function SkillCurriculumStudent() {
  const { id, unit_id } = useParams();
  const dispatch = useDispatch();

  const alert = useSelector((state) => state.alert);
  const history = useHistory();
  const curriculumStudent = useSelector((state) => state.curriculumStudent);
  const authentication = useSelector((state) => state.authentication);
  const dataTopic = useSelector((state) => state.curriculumStudent?.list_topic);
  const dataComposition = useSelector((state) => state.curriculumStudent?.list_composition);

  // const [titleHeadear, setTitleHeadear] = useState("");

  const search = history.location.search;
  const params = new URLSearchParams(search);
  const page = params.get("page");
  const subType = params.get("subType");
  const curriculumId = params.get("curriculumId");
  const isComposition = params.get("isComposition");
  const compositionId = params.get("compositionId");
  const [offsetTopic, setOffsetTopic] = useState(0);
  const [limitTopic] = useState(10);
  const [isLoading, setIsLoading] = useState(true)
  const [isHasMoreTopic, setIsHasMoreTopic] = useState(true)

  useEffect(async () => {
    getData()
    // var index = findIndex(curriculumStudent.data_map.data, { unit_id });
    // if (index !== -1) {
    //   setTitleHeadear(curriculumStudent.data_map.data[index].unit_name);
    // }
  }, [isComposition, compositionId]);

  const getData = (isRefresh = false) => {
    if(subType) {
      setIsHasMoreTopic(true);
      setIsLoading(true);
      setOffsetTopic(0);
      if (isComposition) {
        getDataComposition();
      } else {
        if (unit_id != dataTopic?.data?.list_topic?.[0]?.unit_id || !!isRefresh) {
          if (compositionId) {
            getTopicByComposition(compositionId, 0)
          } else {
            getTopic(0);
          }
        } else {
          setIsLoading(false);
        }
      }
    } else {
      // if (unit_id != curriculumStudent?.lessons?.data?.[0]?.list_lesson?.[0]?.unit_id) {
        // dispatch(studentActions.getLessonCurriculum(unit_id));
        const dataUnit = curriculumStudent?.dataUnitByClass?.[id]?.[unit_id];
        if (!!dataUnit?.data?.length && !isRefresh && !!dataUnit?.saveAt && moment(new Date()).diff(moment(dataUnit?.saveAt), 'hours') < (24*7)) {
          dispatch({
            type: curriculumConstants.GET_LESSON_CURRICULUM_STUDENT,
            lessons: {
              data: dataUnit?.data,
              base_url: dataUnit?.base_url,
              require_learn_master_unit: dataUnit?.require_learn_master_unit,
            },
          });
        } else {
          dispatch(curriculumActions.getDataLessonCurriculumStudent(unit_id, id));
        }
      // }
    }
  }

  const handleScroll = (e) => {
    if (e.target.clientHeight + e.target.scrollTop + 5 >= e.target.scrollHeight && isHasMoreTopic && !!subType) {
      setOffsetTopic(offsetTopic + limitTopic);
      if (compositionId) {
        getTopicByComposition(compositionId, offsetTopic + limitTopic, true)
      } else {
        getTopic(offsetTopic + limitTopic, true);
      }
    }
  }

  const getTopic = (offset, isGetMore) => {
    apiCaller(
      `/api_preparation_course/list_topic?unit_id=${unit_id}&curriculum_id=${id}&offset=${offset}&limit=${limitTopic}`, "GET").then((res) => {
      dispatch({
        type: curriculumConstants.GET_TOPIC_CURRICULUM_STUDENT,
        list_topic: isGetMore ? {
          ...dataTopic,
          data: {
            ...dataTopic?.data,
            list_topic: [...dataTopic?.data?.list_topic, ...res?.data?.list_topic]
          }
        } : res
      });
      if (res?.data?.list_topic?.length < limitTopic) {
        setIsHasMoreTopic(false)
      }
      setIsLoading(false)
    })
  }

  const getTopicByComposition = (composition_id, offset, isGetMore) => {
    apiCaller(
      `/api_preparation_course/list_topic?unit_id=${unit_id}&curriculum_id=${id}&composition_id=${composition_id}&offset=${offset}&limit=${limitTopic}`, "GET").then((res) => {
      dispatch({
        type: curriculumConstants.GET_TOPIC_CURRICULUM_STUDENT,
        list_topic: isGetMore ? {
          ...dataTopic,
          data: {
            ...dataTopic?.data,
            list_topic: [...dataTopic?.data?.list_topic, ...res?.data?.list_topic]
          }
        } : res
      });
      if (res?.data?.list_topic?.length < limitTopic) {
        setIsHasMoreTopic(false)
      }
      setIsLoading(false)
    })
  }

  const getDataComposition = () => {
    apiCaller('/api_preparation_course/composition?unit_id=' + unit_id + "&curriculum_id=" + id, "GET").then((res) => {
      dispatch({
        type: curriculumConstants.GET_COMPOSITION_CURRICULUM_STUDENT,
        list_composition: res
      });
      setIsLoading(false)
    })
  }

  const gotoLessionGramma = useCallback(
    (skill, isblocked) => {
      if (!isblocked) {
        history.push(
          `/${authentication.role}/curriculum/${id}/skill/${unit_id}/lesson/${skill}?page=${page}`
        );
      }
    },
    [curriculumStudent, authentication.role, id, unit_id]
  );

  const handleBack = () => {
    if (page === "homepage") {
      history.push("/" + authentication.role);
    } else if (!!compositionId && !!subType) {
      history.push(
        `/${authentication.role}/curriculum/${id}/skill/${unit_id}` +
        `?subType=${subType}` +
        `&curriculumId=${curriculumId}` + 
        '&isComposition=true'
      );
    } else {
      history.push("/" + authentication.role + "/curriculum/" + id);
    }
  };

  return (
    <div className="sunE-right-container">
      {alert.message &&
        alert.screen == studentConstants.SCREEN_LESSSON_SKILL && (
          <Alert alert={alert} onComplete={() => history.goBack()} />
        )}
      <Header
        title={(!!subType ? 
          !!compositionId ?
          curriculumStudent?.list_composition?.data?.find(item => item?.composition_id == compositionId)?.name :
          curriculumStudent?.data_map?.data?.find(item => item?.unit_id == unit_id)?.unit_name : 
          curriculumStudent?.lessons?.data?.[0]?.list_lesson?.[0]?.unit_name) || ""
        }
        isBack
        clickBack={handleBack}
        component={<>
          <div className="refresh-curriculum-btn-teacher-header" style={{marginRight: 12}} onClick={() => getData(true)}>
            <img src="/assets/images/icon_refresh_curriculum.png" style={{width: '100%', height: '100%', borderRadius: '50%'}}/>
          </div>
        </>}
      />
      <div className="sunE-container-box giaotrinh unit">
        <div className="flex-m">
          <div className="flex-2 sunE-giaotrinh-list scrollbar-custom pad-center-large" onScroll={handleScroll}>
            {/* {console.log(curriculumStudent?.lessons?.data[0]?.list_lesson[0]?.unit_name)} */}
            {!subType && !!curriculumStudent?.lessons?.data?.length && curriculumStudent.lessons.data.map((data, i) => {
              return (
                <a
                  onClick={() => gotoLessionGramma(data.skill, data.is_blocked)}
                  key={i}
                  isblocked={data?.is_blocked}
                >
                  {i === 0 ? (
                    <div className={"box-giaotrinh-gr-first box-shadow"}>
                      <img
                        src={
                          "/assets/images/giaotrinh/" +
                          (data.skill === "test" || data.skill === "exam"
                            ? "mini_test"
                            : data.skill) +
                          ".png"
                        }
                        alt={data.skill}
                      />
                      <div className="item-skill-top">
                        {CricleProcess(
                          !data.is_blocked ? data.process_learn : null
                        )}
                        <h2 className="text-trans" style={{ marginLeft: 20 }}>
                          {convertSkillVN(data?.skill)}
                        </h2>
                      </div>
                    </div>
                  ) : (
                    <div className="box-giaotrinh-gr rel box-shadow">
                      <div className="thumb">
                        <img
                          src={
                            "/assets/images/giaotrinh/" +
                            (data.skill === "test" || data.skill === "exam"
                              ? "mini_test"
                              : data.skill) +
                            ".png"
                          }
                          alt={data.skill}
                        />
                      </div>
                      <div className="box-select-info flex-1">
                        <p className="text-trans semibold">
                          {convertSkillVN(data?.skill)}
                        </p>
                      </div>
                      {CricleProcess(
                        !data.is_blocked ? data.process_learn : null
                      )}
                    </div>
                  )}
                </a>
              );
            })}
            {!_.isEmpty(dataTopic?.data?.list_topic) && !!subType && !isComposition && !isLoading &&
              dataTopic?.data?.list_topic?.map((item, index) => (
                <Link
                  to={
                    `/${authentication.role}/curriculum/${id}/skill/${unit_id}/lesson/${encodeURIComponent(item?.topic_name)}?page=${page}`
                    + `&subType=${subType}` + `&topicId=${item?.topic_id}&curriculumId=${curriculumId}${!!compositionId ? `&compositionId=${compositionId}` : ''}`
                  }
                  key={index}
                >
                  <div className="box-giaotrinh-gr rel box-shadow" style={{marginLeft: 0}}>
                    <div className="box-select-info flex-1">
                      <p className="text-trans semibold">
                        {item?.topic_name}
                      </p>
                    </div>
                  </div>
                </Link>
              ))
            }
            {!_.isEmpty(dataComposition?.data) && !!subType && !!isComposition && !isLoading &&
              dataComposition?.data?.map((item, index) => (
                <Link
                  to={
                    `/${authentication.role}/curriculum/${id}/skill/${unit_id}` +
                    `?subType=${subType}` +
                    `&curriculumId=${curriculumId}` +
                    `&compositionId=${item?.composition_id}`
                  }
                  key={index}
                >
                  <div className="box-giaotrinh-gr rel box-shadow" style={{marginLeft: 0}}>
                    <div className="box-select-info flex-1">
                      <p className="text-trans semibold">
                        {item?.name}
                      </p>
                    </div>
                  </div>
                </Link>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export { SkillCurriculumStudent };
