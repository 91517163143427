import { curriculumConstants } from "../../_constants";
import { userConstants } from "../../_constants";

const firstState = {
  data_map: {
    data_lesson: {
      unit_name: [],
    },
  },
  data_grid: {
    data: {
      lesson_data: {
        unit_name: [],
        data: [],
      },
    },
  },
  view: "map",
  lessons: {
    data: [],
  },
  loading: true,
  list_topic: [],
  list_lesson_topic: [],
  forStudentId: null,
  dataUnitByClass: {}
}

var initialState = {
  ...firstState
};
if (localStorage.getItem("curriculumStudent")) {
  initialState = JSON.parse(localStorage.getItem("curriculumStudent"));
}

const curriculumStudent = (state = initialState, action) => {
  switch (action.type) {
    case curriculumConstants.SAVE_DETAIL_UNIT_STUDENT: {
      const newState = {
        ...state,
        dataUnitByClass: {
          ...state.dataUnitByClass,
          [action?.payload?.classId]: {
            ...state.dataUnitByClass?.[action?.payload?.classId],
            [action?.payload?.unitId]: {
              data: action?.payload?.data,
              base_url: action?.payload?.base_url,
              saveAt: new Date(),
            }
          }
        },
      }
      localStorage.setItem(
        "curriculumStudent",
        JSON.stringify(newState)
      );
      return newState;
    }
    case curriculumConstants.GET_DATA_LIST_CURRICULUM_STUDENT: {
      const newState = {
        ...state,
        loading: true,
        dataCurriculum: action?.payload?.data,
        forStudentId: action?.payload?.userId,
        timeGetDataCurriculum: new Date(),
      }
      localStorage.setItem(
        "curriculumStudent",
        JSON.stringify(newState)
      );
      return newState;
    }
    case curriculumConstants.GET_DATA_MAP_CURRICULUM_STUDENT: {
      const newState = {
        ...state,
        loading: false,
        data_map: action.data_map,
        dataCurriculum: {
          ...state?.dataCurriculum,
          data: {
            list_curriculum: state?.dataCurriculum?.data?.list_curriculum?.map(item => {
              if (item?.id == action?.classId || item?.curriculum_id == action?.curriculumId) {
                return {
                  ...item,
                  data_map: action.data_map
                }
              }
              return item;
            })
          }
        }
      }
      localStorage.setItem(
        "curriculumStudent",
        JSON.stringify(newState)
      );
      return newState;
    }
    case curriculumConstants.GET_DATA_MAP_CURRICULUM_STUDENT_START:
      return {
        ...state,
        loading: true,
      };
    case curriculumConstants.GET_DATA_GRID_CURRICULUM_STUDENT:
      return {
        ...state,
        data_grid: action.data_grid,
      };
    case curriculumConstants.CHANGE_VIEW:
      return {
        ...state,
        view: action.view,
      };
    case curriculumConstants.GET_LESSON_CURRICULUM_STUDENT:
      return {
        ...state,
        lessons: action.lessons,
      };
    case curriculumConstants.GET_TOPIC_CURRICULUM_STUDENT:
      return {
        ...state,
        list_topic: action.list_topic,
      };
    case curriculumConstants.GET_COMPOSITION_CURRICULUM_STUDENT:
      return {
        ...state,
        list_composition: action.list_composition,
      };
    case curriculumConstants.GET_LESSON_TOPIC_CURRICULUM_STUDENT:
      return {
        ...state,
        list_lesson_topic: action.list_lesson_topic,
      };
    case curriculumConstants.RESET_DATA_CURRICULUM_STUDENT:
      localStorage.setItem(
        "curriculumStudent",
        JSON.stringify(firstState)
      );
      return firstState;
    default:
      return state;
  }
};

export { curriculumStudent };
