import React from "react"
import { isJsonString, validWord } from "../../../_helpers/utils";

export default function ResultQuestionRearrange({dataQuestion, skill, question_type, answer, isReview}) {
  let listData = dataQuestion?.[0]?.list_option || [];
  let finalAnswer = JSON.parse(answer);
  if (!isReview) {
    listData = finalAnswer.map(item => {
      const user_choice = isJsonString(item?.user_choice) ? JSON.parse(item?.user_choice) : item?.user_choice
      const data = dataQuestion?.[0]?.list_option?.find(option => {
        const option_text = isJsonString(option?.option_text) ? JSON.parse(option?.option_text) : option?.option_text
        return validWord(user_choice) === validWord(option_text)
      })
      return {
        ...data,
        userChoice: item
      }
    });
  }
  const renderItem = (item) => {
    const words = isJsonString(item?.userChoice?.words) ? JSON.parse(item?.userChoice?.words) : item?.userChoice?.words
    const trueData = dataQuestion?.[0]?.list_option?.find(data => {
      const option_text = isJsonString(data?.option_text) ? JSON.parse(data?.option_text) : data?.option_text
      return words === option_text
    })
    return (
      <div>
        <p style={{
          textAlign: 'auto',
          color: item?.userChoice?.score == 0 ? 'red' : 'unset',
          display: 'flex',
          gap: 4
        }}>
          <p style={{fontWeight: 'bold'}}>{(item?.conversation_index == 1 ? item?.person_a : item?.person_b) + ': '}</p>
          {item?.option_text}
        </p>
        {item?.userChoice?.score == 0 && (
          <div style={{flexDirection: 'row', display: 'flex'}}>
            <p style={{
                flex: 1,
                color: '#62B440',
                alignItems: 'center',
                flexDirection: 'row',
                display: 'flex',
                gap: 4
              }}
            >
              <div>
                <img 
                  src="/assets/images/ico_hover_answer.png"
                  style={{
                    width: 24,
                    height: 20,
                    resizeMode: 'contain',
                  }}
                />
              </div>
              {'  '}
              <p style={{fontWeight: 'bold'}}>{(trueData?.conversation_index == 1 ? trueData?.person_a : trueData?.person_b) + ': '}</p>
              {trueData?.option_text}
            </p>
          </div>
        )}
      </div>
    )
  }

  return (
    <div style={{flex: 1, marginTop: 16}}>
      <div style={{display: 'flex', flexDirection: 'column', gap: 16}}>
        {listData?.map((item, index) => {
          return (
            <div key={index}>
              {renderItem(item, index)}
            </div>
          )
        })}
      </div>
    </div>
  )
}