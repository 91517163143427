export const userConstants = {
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  DELETE_ACCOUNT: "DELETE_ACCOUNT",
  SCREEN_LOGIN: "SCREEN_LOGIN",
  SCREEN_UPDATE_INFOMATION: "SCREEN_UPDATE_INFOMATION",
  SCREEN_FORGOT_PASSWORD: "SCREEN_FORGOT_PASSWORD",
  SCREEN_RESET_PASSWORD: "SCREEN_RESET_PASSWORD",
  ADD_DATA_REGISTER: "ADD_DATA_REGISTER",
  CHANGE_LOGIN_TYPE: "CHANGE_LOGIN_TYPE",
  RESET_LOGIN_TYPE: "RESET_LOGIN_TYPE",
  LOGIN_TYPE_DEFAULT: "default",
  LOGIN_TYPE_SOCIAL: "social",
  SCREEN_REGISTER: "SCREEN_REGISTER",
  SCREEN_COMPLETE_DATA_REGISTER: "SCREEN_COMPLETE_DATA_REGISTER",
  SCREEN_UPDATE_PROFILE: "SCREEN_UPDATE_PROFILE",
  ROLE_STUDENT: "student",
  ROLE_TEACHER: "teacher",
  ROLE_PARENT: "parent",
  GET_PROFILE: "GET_PROFILE",
  GET_PROFILE_NEWS: "GET_PROFILE_NEWS",
  EDIT_PROFILE: "EDIT_PROFILE",
  MESSAGE_REQUEST_CHANGE_PASSWORD_COMPLETE:
    "Email cài đặt lại mật khẩu đã được gửi đến bạn. Bạn hãy truy cập email để đặt lại mật khẩu",
  MESSAGE_CHANGE_PASSWORD_COMPLETE: "Đổi mật khẩu thành công",
  MESSAGE_REGISTER_COMPLETE: "Đăng ký thành công",
  MESSAGE_UPDATE_PROFILE_COMPLETE: "Cập nhật thông tin thành công",
  MESSAGE_UPDATE_AVATAR_COMPLETE: "Cập nhật avatar thành công",
  TEXT_SELECT_ROLE_DEFAULT: "Loại tài khoản",
  GET_ROOM_CHAT: "GET_ROOM_CHAT",
  GET_CLASS_MESSAGE: "GET_CLASS_MESSAGE",
  GET_CLASS_NOTIFICATION: "GET_CLASS_NOTIFICATION",
  GET_DETAIL_MESSAGE: "GET_DETAIL_MESSAGE",
  SEND_MESSAGE: "SEND_MESSAGE",
  GET_CONTACT_INFO: "GET_INFO_CONTACT",
  GET_SETTING_DETAIL: "GET_SETTING_DETAIL",
  GET_TOTAL_MESSAGE: "GET_TOTAL_MESSAGE",
  RESET_CLASS_MESSAGE: "RESET_CLASS_MESSAGE",
  TEXT_SELECT_ROLE: [
    {
      id: "student",
      name: "Tài khoản Học sinh",
    },
    {
      id: "teacher",
      name: "Tài khoản Giáo viên",
    },
    {
      id: "parent",
      name: "Tài khoản Phụ huynh",
    },
  ],
  TEXT_LIST_DAY: [
    {
      id: "Monday",
      name: "Thứ 2",
    },
    {
      id: "Tuesday",
      name: "Thứ 3",
    },
    {
      id: "Wednesday",
      name: "Thứ 4",
    },
    {
      id: "Thursday",
      name: "Thứ 5",
    },
    {
      id: "Friday",
      name: "Thứ 6",
    },
    {
      id: "Saturday",
      name: "Thứ 7",
    },
    {
      id: "Sunday",
      name: "Chủ nhật",
    },
  ],
  LANG_CONFIG: [
    {
      name: "Tiếng Việt",
      id: "vi",
    },
    {
      name: "Tiếng Anh",
      id: "en",
    },
  ],

  SCHOOL_TYPE_PUBLIC: "public",
  SCHOOL_TYPE_PRIVATE: "private",
  SCHOOL_TYPE_FREE: "free",
  RESET_ALL_STATE: "RESET_ALL_STATE",
  ON_ROOTLESSNESS: "ON_ROOTLESSNESS",
  OFF_ROOTLESSNESS: "OFF_ROOTLESSNESS",
  ON_PLACEMENT_TEST: "ON_PLACEMENT_TEST",
  OFF_PLACEMENT_TEST: "OFF_PLACEMENT_TEST",
  SHOW_SIDEBAR_ROOTLESSNESS: "SHOW_SIDEBAR_ROOTLESSNESS",
  HIDE_SIDEBAR_ROOTLESSNESS: "HIDE_SIDEBAR_ROOTLESSNESS",
  ON_PROPOSED_CURRICULUM: "ON_PROPOSED_CURRICULUM",
  OFF_PROPOSED_CURRICULUM: "OFF_PROPOSED_CURRICULUM",
  ON_CHOOSE_LIST_CURRICULUM: "ON_CHOOSE_LIST_CURRICULUM",
  OFF_CHOOSE_LIST_CURRICULUM: "OFF_CHOOSE_LIST_CURRICULUM",
  SET_PRIORITY_CURRICULUM: "SET_PRIORITY_CURRICULUM",
  RESULT_PLACEMENT_TEST: "RESULT_PLACEMENT_TEST",
  RESET_RESULT_PLACEMENT_TEST: "RESET_RESULT_PLACEMENT_TEST",
  CODE_SENT_ON: "CODE_SENT_ON",
  CODE_SENT_OFF: "CODE_SENT_OFF",
  TIMEREMAINING:"TIMEREMAINING",
  REGISTER_STATUS: "REGISTER_STATUS",
  RESET_REGISTER_STATUS: "RESET_REGISTER_STATUS",
  SAVE_DATA_REGISTER: "SAVE_DATA_REGISTER",
  RESET_ALL_PLACEMENT_TEST: "RESET_ALL_PLACEMENT_TEST",
  REGISTER_WITH_OTHER_ZALO: "REGISTER_WITH_OTHER_ZALO",
  LOGIN_WHEN_NOT_VERIFY: "LOGIN_WHEN_NOT_VERIFY",
  RESET_LOGIN_WHEN_NOT_VERIFY: "RESET_LOGIN_WHEN_NOT_VERIFY",
  AVATAR_TEACHER_MALE: 'assets/img_base/teacher_male_dfa.jpg',
  AVATAR_TEACHER_FEMALE: 'assets/img_base/teacher_female_dfa.jpg',
  AVATAR_STUDENT_MALE: 'assets/img_base/student_male_dfa.jpg',
  AVATAR_STUDENT_FEMALE: 'assets/img_base/student_female_dfa.jpg',
};
