import { useDispatch, useSelector } from "react-redux";
import { Header } from "../../../../_components/Admin/Header";
import { useParams } from "react-router-dom";
import { apiCaller, history } from "../../../../_helpers";
import './DetailAssignedHomeWorkStudent.style.scss'
import { useState } from "react";
import moment from 'moment'
import { alertActions } from "../../../../_actions";
import { Alert } from "../../../../_components/Alert";
import cloneDeep from 'lodash/cloneDeep';
import { formatExamDataQuestions, formatLessonDataQuestion } from "../../../../_helpers/formatDataLesson";
import { ReviewExam } from "../../../StudentPage/exam-test/ReviewExam";
import { isJsonString } from "../../../../_helpers/utils";
import { PopUpYesNo } from "../../../../_components/Popup";

export default function DetailAssignedHomeWorkStudent() {
  const authentication = useSelector((state) => state.authentication);
  const alert = useSelector((state) => state.alert);
  const { id, turn_id, student_id } = useParams();
  const heading = new URLSearchParams(window.location.search).get("heading");
  const startTime = new URLSearchParams(window.location.search).get("startTime");
  const endTime = new URLSearchParams(window.location.search).get("endTime");
  const [listData, setListData] = useState([])
  const [isShowReview, setIsShowReview] = useState(false);
  const [isShowReviewLesson, setIsShowReviewLesson] = useState(false);
  const [dataQuestion, setDataQuestion] = useState([]);
  const [infoQuestion, setInfoQuestion] = useState();
  const [resultExam, setResultExam] = useState([]);
  const [resultLesson, setResultLesson] = useState([]);
  const [isOpenModalLocked, setIsOpenModalLocked] = useState(false);
  const dispatch = useDispatch();

  const getData = async () => {
    const url = '/api_exercise_report/turn_user_lesson_details?turn_id=' + turn_id + '&student_id=' + student_id
    try {
      const res = await apiCaller(url, 'get')
      if(res?.status) {
        setListData(res?.data)
      }
    } catch (error) { 
    }
  }

  const handleReview = async (item) => {
    if(item?.score === null || item?.score === undefined) {
      return;
    }
    if(!!item?.is_lock_lesson) {
      setIsOpenModalLocked(true)
      return;
    }
    try {
      const url = '/api_exercise_report/detail_by_exercise?user_exercise_id=' + item?.user_exercise_id;
      const res = await apiCaller(url, 'GET');
      if (!res?.status) {
        dispatch(
          alertActions.error({
            message: res?.msg?.toString(),
            screen: 'DETAIL_ASSIGN_HOMEWORK_STUDENT',
            isShowPopup: true
          })
        );
        return;
      }
      const skill = item?.skill?.toLowerCase() || res?.lesson?.type || res?.data_question?.[0]?.list_option?.[0]?.type || res?.data_question?.[0]?.list_option?.[0]?.skill;
      const question_type = res?.data_question?.[0]?.list_option?.[0]?.question_type;
      if (res?.exercise_data?.exercise_type == 'project' || (skill == 'speaking' && question_type == 3) || (skill == 'writing' && question_type == 7)) {
        history.push("/" +
          authentication.role  +
          `/class/view/${id}/homework/${res?.exercise_data?.exercise_id}/exercise/1/edit/${res?.exercise_data?.id}/${res?.lesson?.type}?isUnMark=true&heading=${res?.lesson?.name}&isGoBack=true`
        )
        return;
      }

      const userChoice = res?.user_choice?.map(item => {
        if (skill === 'vocabulary') {
          return {
            user_choice: item
          }
        }
        let user_choice = '[]';
        const isGetDetailUserTurn = (item?.exercise_type == 'pronunciation' && item?.question_type == 8)
        if (
          res.data_question?.length === 1 && res.data_question?.[0]?.list_option?.length > 1 && !isGetDetailUserTurn
        ) {
          let finalUserChoice = [];
          if(item?.detail_user_turn_style_2?.find(item => !!item?.data?.length)) {
            item?.detail_user_turn_style_2?.map(choice => {
              if(choice?.num_turn >= finalUserChoice[finalUserChoice.length - 1]?.num_turn) {
                finalUserChoice = [];
              }
              finalUserChoice = choice?.data;
            })
          } else {
            item?.detail_user_turn_style_2?.map(item => {
              if(item?.num_turn >= finalUserChoice[finalUserChoice.length - 1]?.num_turn) {
                finalUserChoice = []
              }
              if (finalUserChoice.find(ans => JSON.stringify(ans) == JSON.stringify(item))) {
                return;
              }
              finalUserChoice.push(item)
            })
          }
          user_choice = JSON.stringify(finalUserChoice)
        } else {
          let finalUserChoice = [];
          const detailUserTurn = typeof item?.detail_user_turn === 'object' ? item?.detail_user_turn : []
          detailUserTurn?.map(choice => {
            if(choice?.num_turn >= finalUserChoice[finalUserChoice.length - 1]?.num_turn) {
              finalUserChoice = [];
            }
            finalUserChoice.push(choice);
          })
          user_choice = JSON.stringify(finalUserChoice)
        }
        return {
          ...item,
          score: item?.question_score,
          user_choice,
        }
      })
      let convertDataQuestion;
      if ( !!skill && ["exam", "mini_test"].includes(skill)) {
        const result = userChoice?.map(item => {
          const user_choice = isJsonString(item?.user_choice) ? JSON.parse(item?.user_choice) : item?.user_choice;
          return {
            questionId: item?.question_id,
            answer: user_choice?.[user_choice?.length - 1]?.user_choice
          }
        })
        convertDataQuestion = formatExamDataQuestions(res, skill);
        setResultExam(result)
        setIsShowReviewLesson(false);
      } else {
        convertDataQuestion = formatLessonDataQuestion(res, skill);
        setResultLesson(userChoice)
        setIsShowReviewLesson(true);
      };
      setDataQuestion(convertDataQuestion);
      setInfoQuestion(res?.lesson);
      setIsShowReview(true);
    } catch (err) {
      dispatch(
        alertActions.error({
          message: err?.toString(),
          screen: 'DETAIL_ASSIGN_HOMEWORK_STUDENT',
          isShowPopup: true
        })
      );
    }
  }

  useState(() => {
    getData()
  }, [])

  return (
    <div className="sunE-right-container rel detail-assigned-home-work-page">
      <Header
        title={heading}
        isBack
        clickBack={() =>
          history.push("/" + authentication.role + "/class/view/" + id + "/exercise/detail-assigned/" + turn_id + '?startTime=' + startTime + '&endTime=' + endTime + '&indexTab=0' )
        }
      />
      <div className="sunE-content sunE-class-content">
        <div className="turn-user-lesson-detail-container scrollbar-custom">
          <div className="turn-user-lesson-detail-content">
            {listData?.map(item => (
              <div 
                key={item?.lesson_id} 
                className="turn-user-lesson-detail-item" 
                onClick={() => handleReview(item)}
                style={{cursor: item?.score !== null && Number(item?.score) >= 0 ? 'pointer' : 'default'}}
              >
                <div className="turn-user-lesson-detail-avatar">
                  <img 
                    src={
                      "/assets/images/teacher/giaobai/" +
                      item.skill?.toLocaleLowerCase() +
                      ".png"
                    } 
                  />
                </div>
                <div className="turn-user-lesson-detail-info">
                  <div className="turn-user-lesson-detail-name">
                    {item?.level && <span 
                      style={{
                        backgroundColor: item?.level === 'easy' ? '#84C241' : item?.level === 'normal' ? '#F8AF36' : '#EB5757' ,
                        textTransform: 'capitalize'
                      }}
                    >
                      {item?.level === 'normal' ? 'Medium' : item?.level}
                    </span>}
                    {/* {item?.lesson_name} */}
                    {item?.lesson_topic}
                  </div>
                  <h3 className='desc two-line' style={{color: '#00A69C'}}>
                    {item.lesson_name}
                  </h3>
                  {(item?.unit_number || item?.unit_name) && 
                    <p style={{fontSize: '18px'}}>
                      {(item?.unit_number ? ('Unit ' + item?.unit_number) : '') + 
                      (item?.unit_number && item?.unit_name ? ' - ' : '') +
                      (item?.unit_name ? item.unit_name : '')
                      }
                    </p>
                  }
                  <p style={{fontSize: '18px'}}>{item?.curriculum_name}</p>
                  <p style={{fontSize: '18px'}}>{"Mã bài: " + (item?.lesson_id || item?.exercise_id)}</p>
                  <div className="flex flex-m">
                    <span>
                      <span style={{color: !!item?.status ? '#84C241' : '#EB5757'}}>{!!item?.status ? 'Đã làm' : 'Chưa làm'}</span>
                      {(!!item?.status || item?.is_overdue) && <span>{' - '}</span>}
                      {(!!item?.status || item?.is_overdue) && <span style={{color: item?.is_overdue ? '#EB5757' : '#84C241'}}>{item?.is_overdue ? 'Quá hạn' : 'Đúng hạn'}</span>}
                    </span>
                    {item?.date && <span>{'Làm ngày ' + moment(item.date).format('DD/MM/YYYY')}</span>}
                    <span>
                      Điểm:
                      {' '}
                      <span style={{color: '#00BEB4', fontWeight: 'bold'}}>{item?.score !== null && Number(item?.score) >= 0 ? Math.round(Number(item.score) * 100)/100 : '__'}</span>
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <ReviewExam
        examInfo={infoQuestion}
        isShow={isShowReview}
        onClose={() => setIsShowReview(false)}
        examId={infoQuestion?.id}
        listQuestions={dataQuestion}
        isShowResultLesson={isShowReviewLesson}
        resultExam={resultExam}
        resultLesson={resultLesson}
      />
      {isOpenModalLocked && (
        <PopUpYesNo
          width={"360px"}
          onClickYes={() => {
            history.push(`/${authentication?.role}/more/license`);
            setIsOpenModalLocked(false);
          }}
          onClickNo={() => setIsOpenModalLocked(false)}
          message={
            "Bạn không thể truy cập nội dung này. Vui lòng nâng cấp tài khoản để tiếp tục!"
          }
          labelNo={"Đóng"}
          labelYes={"Nâng cấp"}
          hideButtonNo={false}
        />
      )}
      {alert.message && alert.screen === 'DETAIL_ASSIGN_HOMEWORK_STUDENT' && 
        <Alert alert={alert} />
      }
    </div>
  )
}